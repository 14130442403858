@import '../../../../global.scss';

.contact {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;    
    flex-direction: column;
     &__header{
        align-items: center;
        justify-content: center;
        display: flex;
        margin-top: 30px;
        font-size: 30px;
     }

     &__form{
      margin-top: 1%;
      margin-bottom: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: rgb(22, 180, 135);
      border-radius: 20px;
      box-shadow: 5px 5px 5px 0px rgba($color: black , $alpha: 0.2);
      width: 60%;
      padding: 1%;
      &__title{
         font-size: 20px;
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: center;
      }
      &__name{
         display: flex;
         gap: 10%;
         justify-content: space-between;
         align-items: center;
         padding: 20px; 
         height: 100%;
         
         &__label{
            font-size: 22px;
         }
         &__input{
            width: 80%;
            height: 30px;
            background-color: aliceblue;
            border-radius: 20px;
            box-shadow: 5px 5px 5px 0px rgba($color: black , $alpha: 0.2);
            border: none;

            &::placeholder {
               color: #aaa; 
               font-style: italic;
               font-size: 15px; 
             }

         }
         &__input2{
            height: 100px;
            width: 80%;
            background-color: aliceblue;
            border-radius: 20px;
            box-shadow: 5px 5px 5px 0px rgba($color: black , $alpha: 0.2);
            border: none;
            &::placeholder {
               color: #aaa; 
               font-style: italic;
               font-size: 15px; 
             }
         }
      }
      &__btn{
         width: 100%;
         height: 100%;
         display: flex;
         align-items: center;
         justify-content: center;
         &__b{
            height: 30px;
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            border: none;
            background-color: rgb(0, 98, 255);
            box-shadow: 5px 5px 5px 0px rgba($color: black , $alpha: 0.2);
            font-size: 20px;
         }
      }
     }

     &__body{
        display: flex;
        flex-direction: row;
        gap: 40px;
        border-top: solid 2px;
        width: 95%;
        padding: 1%;
  
        &__phone{
         justify-content: center;
         align-items: center;
         display: flex;
         flex-direction: column;
         width: 33%;
      
         &__img{
            height: 150px;
            width: 150px;
         }
         &__info{   
            border-radius: 10px; 
            width: 100%;
            min-height: 220px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            &__title{
              position: absolute;
              top: 0;
            }
            &__p{
               margin-top: 0%;
            }
         }
      }
        &__email{
         justify-content: center;
         align-items: center;
         display: flex;
         flex-direction: column;        
         width: 40%;
         border-left: solid 1px;
         border-right: solid 1px;
         &__img{
            height: 150px;
            width: 150px;
         }
         &__info{ 
            width: 100%;
            min-height: 220px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            &__title{
              position: absolute;
              top: 0;
            }
            &__p{
               margin-top: 0%;
            }
         }
       }
       &__location{
         justify-content: center;
         align-items: center;
         display: flex;
         flex-direction: column;        
         width: 33%;
   
         &__img{
            height: 150px;
            width: 150px;
         }
         &__info{
            border-radius: 10px; 
            width: 100%;
            min-height: 220px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            &__title{
              position: absolute;
              top: 0;
            }
            &__p{
               margin-top: 10%;
            }
         }
       }
     }


}

@media (max-width: 900px) {
   .contact {
      &__form{
         width: 70%;
         &__title{
            font-size: 19px;
         }
         &__name{            
            &__label{
               font-size: 18px;
            }
            &__input{   
               &::placeholder {
                  font-size: 14px; 
                }
            }
            &__input2{
               &::placeholder {
                  font-size: 14px; 
                }
            }
         }
         &__btn{
            &__b{
               width: 34%;
               font-size: 18px;
            }
         }
        }
   
        &__body{     
           &__phone{
            &__img{
               height: 130px;
               width: 130px;
            }
            &__info{   
               &__title{
                  font-size: 24px;
               }
               &__p{
                  font-size: 16px;
               }
            }
         }
           &__email{
            &__img{
               height: 130px;
               width: 130px;
            }
            &__info{   
               &__title{
                  font-size: 24px;
               }
               &__p{
                  font-size: 16px;
               }
            }
          }
          &__location{
            &__img{
               height: 130px;
               width: 130px;
            }
            &__info{   
               &__title{
                  font-size: 24px;
               }
               &__p{
                  font-size: 16px;
               }
            }
          }
      }  
  }  
}


@media (max-width: 700px) {
   .contact {
      &__form{
         width: 90%;
      }
        &__body{     
         padding: 1%;
         margin-top: 0px;
           &__phone{
            &__img{
               height: 100px;
               width: 100px;
            }
            &__info{   
               &__title{
                  font-size: 20px;
               }
               &__p{
                  font-size: 14px;
               }
            }
         }
           &__email{
            &__img{
               height: 100px;
               width: 100px;
            }
            &__info{   
               &__title{
                  font-size: 20px;
               }
               &__p{
                  font-size: 14px;
               }
            }
          }
          &__location{
            &__img{
               height: 100px;
               width: 100px;
            }
            &__info{   
               &__title{
                  font-size: 20px;
               }
               &__p{
                  font-size: 14px;
               }
            }
          }
      }  
  }  
}

@media (max-width: 550px) {
   .contact {
      &__form{
         width: 90%;
      }
        &__body{     

           &__phone{
            &__img{
               height: 80px;
               width: 80px;
            }
            &__info{   
               &__title{
                  font-size: 18px;
               }
               &__p{
                  font-size: 10px;
               }
            }
         }
           &__email{
            width: 50%;
            &__img{
               height: 80px;
               width: 80px;
            }
            &__info{   
               &__title{
                  font-size: 18px;
               }
               &__p{
                  font-size: 10px;
               }
            }
          }
          &__location{
            &__img{
               height: 80px;
               width: 80px;
            }
            &__info{   
               &__title{
                  font-size: 18px;
               }
               &__p{
                  font-size: 10px;
               }
            }
          }
      }  
  }  
}

@media (max-width: 450px) {
   .contact {
      &__header{
         font-size: 22px;
      }
      &__form{
         width: 90%;
         &__title{
            font-size: 18px;
         }
         &__name{            
            &__label{
               font-size: 17px;
            }
            &__input{   
               &::placeholder {
                  font-size: 14px; 
                }
            }
            &__input2{
               &::placeholder {
                  font-size: 14px; 
                }
            }
         }
         &__btn{
            &__b{
               width: 40%;
               font-size: 16px;
            }
         }
        }
        &__body{     
         &__phone{
          &__img{
             height: 60px;
             width: 60px;
          }
          &__info{   
             &__title{
                font-size: 16px;
             }
             &__p{
                font-size: 9px;
             }
          }
       }
         &__email{
          width: 70%;
          &__img{
             height: 60px;
             width: 60px;
          }
          &__info{   
             &__title{
                font-size: 16px;
             }
             &__p{
                font-size: 9px;
             }
          }
        }
        &__location{
          &__img{
             height: 60px;
             width: 60px;
          }
          &__info{   
             &__title{
                font-size: 16px;
             }
             &__p{
                font-size: 9px;
             }
          }
        }
    }  
  }  
}