@import '../../../../global.scss';

.certificate {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 

    &__title{
        align-items: center;
        justify-content: center;
        font-size: 30px;
        display: flex;
        align-items: center;
     }
     &__wrapper{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px; 
        padding: 1%;
       }
      &__card{
       width: 100%;
       height: 100%;
       box-shadow: 5px 5px 10px 2px rgba($color: black , $alpha: 0.4);
      }
    
   }

