@import '../../../../global.scss';

.project {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
     
     &__title{
        align-items: center;
        justify-content: center;
        font-size: 30px;
        display: flex;
        align-items: center;
     }
     &__wrapper{
       display: grid;
       grid-template-columns: repeat(2, 1fr);
       border-radius: 20px;
       margin-top: 30px;
       gap: 20px; 
       padding: 1%;
      }
     &__card{
      width: 100%;
      height: 100%;
      display: flex;
      border-radius: 20px;
      color: aqua;
      box-shadow: 5px 5px 5px 0px rgba($color: black , $alpha: 0.2);
     }
}

@media (max-width:1100px){
   .project {
   &__wrapper{
     display: grid;
     grid-template-columns: repeat(1, 1fr);
    }

  }
}


.modal{
   &__appcard {
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      position: fixed;
      position: relative;

      &__name{
         align-items: center;
         justify-content: center;
         font-size: 30px;
         color: white;
      }

      &__icon{
        margin-top: 1%;
        width: 70%;
        height: 70%;
        border-radius: 30px;
        align-items: center;
        justify-content: center;
        display: flex;
      }

       &__info{
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: white;
          margin-left: 10%;
          margin-right: 10%;
          
       }

      }
  }

  @media (max-width:700px){
   .apps {
      overflow-y: scroll;

       &__wrapper{
         flex-direction: column;
        }
  }
  }