@import '../../global.scss';

.footer {
    display: flex;
    flex-direction: row;
    background-color: aqua;
    height: 66px;
    padding: 0 50px;
    align-items: center;
    border-top: solid 3px rgb(5, 11, 100);
    box-shadow: 0px 0px 5px 5px rgba($color: black , $alpha: 0.2);
    &__copyright-p{
        &__span{
            color: red;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }

    &__links{
        display: flex;
        margin-left: auto;
        margin-right: 50px;
    }

    &__link{
        display: flex;
        align-items: center;
        gap: 10px;
        margin-right: 30px;
        color: black;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
        &__img{
            width: 44px;
            height: 44px;
        }
    }
}


@media (max-width:850px){
    .footer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        height: 100%;
        &__copyright-p{
            font-size: 18px;
            margin-right: 10px;
            &__span{
                font-size: 18px;
            }
        }
        &__link{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            width: 100%;
            height: 100%;
            font-size: 16px;
            &__img{
                width: 40px;
                height: 40px;
            }
        }
    }
}

@media (max-width:700px){
    .footer {
    display: grid;
    height: 6px;
    padding: 0 50px;
    align-items: center;
    border-top: solid 3px rgb(5, 11, 100);
    box-shadow: 0px 0px 5px 5px rgba($color: black , $alpha: 0.2);
  
        &__copyright-p{
            font-size: 14px;
            margin-right: 20px;
            &__span{
                font-size: 14px;
            }
        }
        &__link{
            font-size: 14px;

            &__img{
                width: 34px;
                height: 34px;
            }
        }
    }
}

@media (max-width:600px){
    .footer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        height: 100%;
        &__copyright-p{
            font-size: 14px;
            margin-right: 10px;
            &__span{
                font-size: 14px;
            }
        }
        &__link{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            width: 100%;
            height: 100%;
            font-size: 14px;
            &__img{
                width: 35px;
                height: 35px;
            }
        }
    }
}

@media (max-width:450px){
    .footer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        height: 100%;
        
        &__copyright-p{
            font-size: 12px;
            margin-right: 1px;
            &__span{
                font-size: 12px;
            }
        }
        &__link{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            width: 100%;
            height: 100%;
            font-size: 14px;
            margin-right: 20px;
            &__img{
                width: 35px;
                height: 35px;
            }
        }
    }
}
