@import '../../global.scss';

.body {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    flex-direction: column;
    background-color: aqua;
    margin-top: 15px;
    overflow-y: scroll;
}

@media (max-width:700px){
    .body {
        min-height: 90vh;
    }
}

@media (max-width:550px){
    .body {
        min-height: 80vh;
    }
}