@import '../../../../global.scss';

.home {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;    
    display: flex;
    flex-direction: column;
    &__head{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20%;
      &__left{
         display: flex;
         flex-direction: column;

         &__h1{ 
            background-color: rgb(196, 202, 255);
            width: 200%;
            border-radius: 20px;
            font-size: 60px;
            white-space: nowrap;
            text-shadow: 3px 2px 3px rgb(76, 61, 61);
            background: linear-gradient(to bottom, rgb(196, 202, 255) 95%, red 10%); 
            &__name{
               color: red;
               font-size: 60px;
               text-shadow: 0px 0px 0px rgb(76, 61, 61);
            }
         }
         &__h3{
            margin-top: -20px;
            font-size: 40px;  
            text-shadow: 3px 2px 3px rgb(76, 61, 61);
         }
         &__btn{
            background-color: rgb(159, 88, 226);
            border-radius: 10px;
            border: none;
            box-shadow: 5px 5px 10px 2px rgba($color: black , $alpha: 0.4);
            width: 24%;
            height: 40px;
            
            cursor: pointer;
         }
      }

      &__sahand{
         clip-path: inset(0px 10px 40px 0px);
       }
    }

    &__about{
    
      margin-top: 10%;
      border-top: solid 2px;
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10%;
      &__h3{
         white-space: nowrap;
         font-size: 25px;
       
      }
      &__h4{
         padding: 5%;
         font-size: 18px;
      }
      
    }

    &__skill{
      margin-bottom: 10%;
      border-top: solid 2px;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      &__h3{
         white-space: nowrap;
         font-size: 25px;
         margin-bottom: 6%;
      }
      
      &__skil{
         display: grid;
         grid-template-columns: repeat(5, 1fr);
         gap: 50px; 
         padding: 1%;
      }

    }

    &__service{
      margin-bottom: 10%;
      border-top: solid 2px;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__h3{
         white-space: nowrap;
         font-size: 25px;
         margin-bottom: 6%;
      }
      &__serv{
         display: grid;
         grid-template-columns: repeat(2, 1fr);
         gap: 50px; 
         padding: 1%;
         &__s{
           display: flex;
           flex-direction: column;
           justify-content: center;
           align-items: center;
           background-color: rgb(120, 151, 161);
           border-radius: 10px;
           padding: 4%;
           font-size: 20px;
           box-shadow: 5px 5px 10px 2px rgba($color: black , $alpha: 0.4);
         }
         &__h4{
            font-size: 18px;
         }
         
      }
    }

    &__contact{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10%;
      width: 50%;
      height: 120%;

      &__link{
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 10px 2px rgba($color: black , $alpha: 0.4);
        background-color: darken( rgb(47, 191, 31),3%);
        text-decoration: none;
        font-size: 30px;
        border-radius: 10px;
        width: 50%;
        height: 120%;
        color: black;
        cursor: pointer;
        &:hover{
         box-shadow: 0px 0px 40px 20px rgba($color: rgb(43, 255, 0) , $alpha: 0.9);
         height: 120%;
         color: white;
         background-color: rgb(2, 1, 4);
     }
   }
  }
}


@media(max-width:1170px){
   .home {
      &__head{
         &__left{
            display: flex;
            flex-direction: column;
            margin-left: 10%;
            &__h1{ 
               width: 200%;
               font-size: 50px;
               margin-left: 5%;
               &__name{
                  font-size: 50px;
               }
            }
            &__h3{
               margin-top: -10px;
               font-size: 40px;
               margin-left: 5%;
            }
            &__btn{
               margin-left: 5%;
               width: 30%;
            }
         }
         &__sahand{
            width: 50%;
            transform: translateX(-99px);
          }
       }
       &__about{
         flex-direction: column;
         gap: 0%;
         &__h3{
            font-size: 25px;
         }
         &__h4{
            padding: 1%;
            font-size: 20px;
         }
       }
   
       &__skill{
         &__h3{
            font-size: 25px;
            margin-bottom: 6%;
         }
         
         &__skil{
            grid-template-columns: repeat(4, 1fr);
            gap: 50px; 
            padding: 1%;
         }
       }

       &__service{
         width: 80%;
         &__h3{
            font-size: 25px;
         }
         &__serv{
            grid-template-columns: repeat(2, 1fr);
            gap: 20px; 
            &__s{
              font-size: 20px;
            }
            &__h4{
               font-size: 18px;
            }
         }
       }
   
       &__contact{
         width: 60%;

         &__link{
           font-size: 25px;
      }
   }  
}
}

@media(max-width:950px){
   .home {
      &__head{
         &__left{
            display: flex;
            flex-direction: column;
            margin-left: 10%;
            &__h1{ 
               width: 200%;
               font-size: 40px;
               margin-left: 5%;
               &__name{
                  font-size: 40px;
               }
            }
            &__h3{
               margin-top: -10px;
               font-size: 30px;
               margin-left: 5%;
            }
            &__btn{
               margin-left: 5%;
               width: 30%;
            }
         }

         &__sahand{
            width: 50%;
            transform: translateX(-79px);
          }
       }
}
}

@media(max-width:800px){
   .home {
      &__head{
         &__left{
            display: flex;
            flex-direction: column;
            margin-left: 10%;
            &__h1{ 
               width: 150%;
               font-size: 30px;
               margin-left: 20%;
               &__name{
                  font-size: 30px;
               }
            }
            &__h3{
               margin-top: -10px;
               font-size: 30px;
               margin-left: 20%;
            }
            &__btn{
               margin-left: 20%;
               width: 30%;
            }
         }
         &__sahand{
            width: 50%;
            transform: translateX(-80px);
          }
       }
       &__about{
         flex-direction: column;
         gap: 0%;
         &__h3{
            font-size: 20px;
         }
         &__h4{
            padding: 1%;
            font-size: 18px;
         }
       }
   
       &__skill{
         &__h3{
            font-size: 20px;
            margin-bottom: 6%;
         }
         
         &__skil{
            grid-template-columns: repeat(3, 1fr);
            gap: 30px; 
            padding: 0%;
         }
       }

       &__service{
         width: 80%;
         &__h3{
            font-size: 20px;
         }
         &__serv{
            grid-template-columns: repeat(1, 1fr);
         }
       }
   
       &__contact{
         width: 80%;

         &__link{
           font-size: 25px;
      }
   }  
}
}

@media(max-width:500px){
   .home {
      &__head{
         gap: 10%;
         &__left{
            display: flex;
            flex-direction: column;
            margin-left: 10%;
            &__h1{ 
               width: 150%;
               font-size: 30px;
               margin-left: 5%;
               &__name{
                  font-size: 30px;
               }
            }
            &__h3{
               margin-top: -10px;
               font-size: 20px;
               margin-left: 5%;
            }
            &__btn{
               margin-left: 5%;
               width: 40%;
            }
         }
         &__sahand{
            width: 50%;
            transform: translateX(-70px);
          }
       }
       &__about{
         flex-direction: column;
         gap: 0%;
         &__h3{
            font-size: 20px;
         }
         &__h4{
            padding: 1%;
            font-size: 17px;
         }
       }
   
       &__skill{
         &__h3{
            font-size: 20px;
            margin-bottom: 6%;
         }
         
         &__skil{
            grid-template-columns: repeat(2, 1fr);
            gap: 50px; 
            padding: 1%;
         }
       }

       &__service{
         width: 80%;
         &__h3{
            font-size: 20px;
         }
         &__serv{
            grid-template-columns: repeat(1, 1fr);
            gap: 20px; 
            &__s{
              font-size: 19px;
            }
            &__h4{
               font-size: 18px;
            }
         }
       }
   
       &__contact{
         width: 100%;

         &__link{
           font-size: 25px;
      }
   }  
}
}

@media(max-width:450px){
   .home {
      &__head{
         gap: 10%;
         &__left{
            display: flex;
            flex-direction: column;
            margin-left: 10%;
            &__h1{ 
               width: 150%;
               font-size: 25px;
               margin-left: 10%;
               &__name{
                  font-size: 25px;
               }
            }
            &__h3{
               margin-top: -10px;
               font-size: 18px;
               margin-left: 10%;
            }
            &__btn{
               margin-left: 10%;
               width: 40%;
            }
         }
         &__sahand{
            width: 45%;
            transform: translateX(-39px);
          }
       }
       &__about{
         flex-direction: column;
         gap: 0%;
         &__h3{
            font-size: 20px;
         }
         &__h4{
            padding: 1%;
            font-size: 17px;
         }
       }
   
       &__skill{
         &__h3{
            font-size: 20px;
            margin-bottom: 6%;
         }
         
         &__skil{
            grid-template-columns: repeat(2, 1fr);
            gap: 50px; 
            padding: 1%;
         }
       }

       &__service{
         width: 80%;
         &__h3{
            font-size: 20px;
         }
         &__serv{
            grid-template-columns: repeat(1, 1fr);
            gap: 20px; 
            &__s{
              font-size: 19px;
            }
            &__h4{
               font-size: 18px;
            }
         }
       }
   
       &__contact{
         width: 100%;

         &__link{
           font-size: 25px;
      }
   }  
}
}
