@import '../../../../global.scss';

.web {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    align-items: center;
  
     &__header{
      margin-top: 5%;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        display: flex;
     }
     &__body{
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px; 
      
      &__card{
         background-color: aliceblue;
         width: 100%;
         height: 100px;
         display: flex;
         flex-direction: row;
         align-items: left;
         justify-content: left;
         border-radius: 20px;
         box-shadow: 6px 6px 6px 1px rgba($color: black , $alpha: 0.2);
         
         &__intro{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 80%;
            
            & h4{
               margin-left: 30px;
               height: 1px;
            }
            & p{
               margin-left: 30px;
               height: 1px;
            }
         }
         &__img{
            height: 100%;
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center; 
            &__icon{
               height: 80px;
               width: 80px;
               border-radius: 20px;
            }
         }
      }
   }
}

@media(max-width:570px){
   .web {
      &__body{
      &__card{
         &__intro{
            & h4{
               margin-left: 20px;
               font-size: 15px;
            }
            & p{
               margin-left: 20px;
               font-size: 12px;
            }
         }
         &__img{
            &__icon{
               height: 50px;
               width: 50px;
               border-radius: 20px;
            }
         }
      }
   }
 }
}


@media(max-width:400px){
   .web {
      &__body{
      &__card{
         &__intro{
            & h4{
               margin-left: 20px;
               font-size: 13px;
            }
            & p{
               margin-left: 20px;
               font-size: 10px;
            }
         }
         &__img{
            &__icon{
               height: 50px;
               width: 50px;
               border-radius: 10px;
               margin-right: 10px;
            }
         }
      }
   }
 }
}