@import '../../global.scss';

.modal {
    position: fixed;
    z-index: 1;
    box-shadow: 5px 5px 5px 0px rgba($color: black , $alpha: 0.2);
    top: 50%;
    left: 50%;
    width: 80%;
    min-height: 80vh;
    padding: 20px;
    transform: translate(-50%,-50%);
    background-color: rgba($color: rgb(0, 26, 39), $alpha: 0.9);
    color: rgb(0, 0, 0);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 300ms cubic-bezier(.03,.72,.44,.6) 100ms;
    padding: 10px;  

        &__close{
            position:  absolute;
            top: 10px;
            right: 10px;
            border: none;
            background-color: rgb(0, 26, 39) ;
            cursor: pointer;
            width: 20px;
            height: 20px;
            &:hover{     
                transition: all 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;         
                width: 25px;
                height: 25px;        
            }
        }
    
}

@media(max-width:700px){
    .modal {
        width: 80%;
        
    }
}