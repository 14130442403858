.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: aqua;
  &__header {
    margin-bottom: auto;
    flex: 1;
  }
  &__body{
    display: flex;
    flex: 1;
  }
  &__footer{
    margin-top: auto;
    flex: 1;
  }
}

@media (max-width: 700px) {
  .App {
    &__header {
      margin-bottom: auto;
    }
    &__body{
      display: flex;
    }
    &__footer{
      margin-top: auto;
    }

  }
}