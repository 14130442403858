@import '../../../../global.scss';

.cv {
    width: 100%;
    height: 100%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 30px;

    &__pdf{
        width: 90%;
        height: 80%; 
        z-index: 0;
    }
     
}