@import '../../../../global.scss';

.appcard {
    width: 100%;
    height: 100%;
    background-color: rgb(50, 156, 255);
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    gap: 10px;
     
    &__icon{
      width: 300px;
      height: 300px;
      border-radius: 20px;
      padding: 10px;
    }
    &__describ{
      
      &__name{
         align-items: center;
         justify-content: center;
         font-size: 20px;
      }
      &__info{
         align-items: center;
         justify-content: center;
         font-size: 17px;
         overflow: hidden;
         text-overflow: ellipsis;
         width: 100%; 
         height: 200px;
         white-space:normal;
      }
    }
}


@media (max-width:650px){
  .appcard {
    &__icon{
      width: 250px;
      height: 250px;
    }
    &__describ{
      &__name{
         font-size: 18px;
      }
      &__info{
         font-size: 15px;
         width: 100%; 
         height: 160px;
      }
    }
 }
}

@media (max-width:500px){
  .appcard {
    &__icon{
      width: 200px;
      height: 200px;
    }
    &__describ{
      &__name{
         font-size: 18px;
      }
      &__info{
         font-size: 15px;
         width: 100%; 
         height: 140px;
      }
    }
 }
}

@media (max-width:450px){
  .appcard {
    &__icon{
      width: 150px;
      height: 150px;
    }
    &__describ{
      &__name{
         font-size: 15px;
      }
      &__info{
         font-size: 13px;
         width: 100%; 
         height: 70px;
      }
    }
 }
}