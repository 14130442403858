@import '../../../../global.scss';

.skill {
    
  
  &__name {
    font-weight: bold;
    margin-bottom: 5px;
    white-space: nowrap;
  }
  
  &__bar {
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 24px;
    width: 130px;
  }
  
  &__level {
    background-color: #4caf50; 
    height: 100%;
    color: white;
    text-align: center;
    line-height: 24px; 
    white-space: nowrap;
    overflow: hidden;
    transition: width 0.3s ease-in-out;
  }
}  