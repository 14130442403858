@import '../../../../global.scss';

.cercard {
    width: 100%;
    height: 100%;
    background-color: rgb(50, 156, 255);
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    gap: 10px;
     
    &__cer{
      width: 100%;
      height: 100%;
    }
}
