@import '../../global.scss';

.header {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    
    &__menu{
        height: 33px;
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 2px;
        position: fixed;
        z-index: 1000;
        &__li1{
            width: 15%;
            height: 100%;
            background-color: rgb(0, 229, 255);
            font-size: 21px;
            border: none;
            border-radius: 0px 0px 10px 10px;
            cursor: pointer;
            box-shadow: 5px 3px 5px 2px rgba($color: black , $alpha: 0.2);
            transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: black;
            &:hover{
                transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                height: 120%;
                background-color: darken( rgb(0, 229, 255),3%);
            }
            }
        &__li12{
            width: 20%;
            height: 100%;
            background-color: rgb(0, 229, 255);
            font-size: 21px;
            border: none;
            border-radius: 0px 0px 10px 10px;
            cursor: pointer;
            box-shadow: 5px 3px 5px 2px rgba($color: black , $alpha: 0.2);
            transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: black;    
            &:hover{
                transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                height: 120%;
                background-color: darken( rgb(0, 229, 255),3%);
            }
            }   
        &__li2{
            width: 20%;
            height: 100%;
            background-color: rgb(0, 229, 255);
            font-size: 21px;
            border: none;
            border-radius: 0px 0px 10px 10px;
            cursor: pointer;            
            box-shadow: 5px 3px 5px 2px rgba($color: black , $alpha: 0.2);
            transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: black;
            &:hover{
                transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                height: 120%;
                background-color: darken( rgb(0, 229, 255),3%);
            }    
            }
        &__li3{
            width: 20%;
            height: 100%;
            background-color: rgb(0, 229, 255);
            font-size: 21px;
            border: none;
            border-radius: 0px 0px 10px 10px;
            cursor: pointer;
            box-shadow: 5px 3px 5px 2px rgba($color: black , $alpha: 0.2);
            transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: black;
            &:hover{
                transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                height: 120%;
                background-color: darken( rgb(0, 229, 255),3%);
            }            
            }     
        &__li4{
            width: 20%;
            height: 100%;
            background-color: rgb(0, 229, 255);
            font-size: 21px;
            border: none;
            border-radius: 0px 0px 10px 10px;
            cursor: pointer;
            box-shadow: 5px 3px 5px 2px rgba($color: black , $alpha: 0.2);
            transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: black;
            &:hover{
                transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                height: 120%;
                background-color: darken( rgb(0, 229, 255),3%);
            }
            }    
        &__li5{
            width: 20%;
            height: 100%;
            background-color: rgb(0, 229, 255); 
            font-size: 21px;
            border: none;
            border-radius: 0px 0px 10px 10px;
            cursor: pointer;
            box-shadow: 5px 3px 5px 2px rgba($color: black , $alpha: 0.2);
            transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: black;
            &:hover{
                transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                height: 120%;
                background-color: darken( rgb(0, 229, 255),3%);
            }
            }
        &__li6{
            width: 20%;
            height: 100%;
            background-color: rgb(0, 229, 255); 
            font-size: 21px;
            border-radius: 0px 0px 10px 10px;
            cursor: pointer;
            box-shadow: 5px 3px 5px 2px rgba($color: black , $alpha: 0.2);
            transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: black;
            &:hover{
                transition: height 800ms  cubic-bezier(.03,.72,.44,.6) 100ms;
                height: 120%;
                background-color: darken( rgb(0, 229, 255),3%);
            }
            }        
            &__li7{
                width: 10%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                &__icon{
                    width: 44px;
                    height: 44px;
                }
            }                                                 
    }    
}

@media(max-width:700px){
    .header {
    &__menu{    
        height: 33px;
        width: 100%;
        gap: 1px;
        &__li1{
            font-size: 13px;
            &:hover{
                height: 110%;
            }
            }
        &__li2{
            font-size: 13px;
            &:hover{
                height: 110%;
            }
            }
        &__li3{
            font-size: 13px;
            &:hover{
                height: 110%;
            }      
            }     
        &__li4{
            font-size: 13px;
            &:hover{
                height: 110%;
            }
            }    
        &__li5{
            font-size: 13px;
            &:hover{
                height: 110%;
            }
            }
        &__li6{
            font-size: 13px;
            &:hover{
                height: 110%;
            }
            }      
            
        }    
    }
}

@media(max-width:500px){
    .header {
    &__menu{    
        height: 33px;
        width: 100%;
        gap: 1px;
        &__li1{
            font-size: 12px;
            &:hover{
                height: 110%;
            }
            }
        &__li2{
            font-size: 12px;
            &:hover{
                height: 110%;
            }
            }
        &__li3{
            font-size: 12px;
            &:hover{
                height: 110%;
            }      
            }     
        &__li4{
            font-size: 12px;
            &:hover{
                height: 110%;
            }
            }    
        &__li5{
            font-size: 12px;
            &:hover{
                height: 110%;
            }
            }
        &__li6{
            font-size: 12px;
            &:hover{
                height: 110%;
            }
            }      
        }    
    }
}